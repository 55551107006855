<template>
  <StoryblokComponent v-if="story" :blok="story.content" :data-page="url" />
</template>

<script setup>
  import { usePageStore } from "@/stores/page";
  import { useRobotsRule } from "#imports";

  definePageMeta({
    middleware: ["check-country"],
  });

  const { slug } = useRoute().params;
  const url = computed(() => {
    if (!slug) return "index";
    return slug.join("/").replace(/^\/+|\/+$/g, "");
  });

  const isPreview = useRuntimeConfig().public.CMS_ENV !== "production";
  const resolve_relations = ["article-slider.articles", "page-slider.pages", "hero-article.article"];

  const pageStore = usePageStore();

  const { data: story } = await useAsyncData(`${url.value}`, async () => {
    const storyData = await useAsyncStoryblok(`${url.value}`, {
      version: isPreview ? "draft" : "published",
      resolve_relations,
    });
    return storyData.value;
  });

  pageStore.updatePage(story.value);

  if (!isPreview) {
    if (!story.value) {
      console.error("[...slug].vue: ", url.value, JSON.stringify(story.value, null, 2));
      showError({ statusCode: 404, statusMessage: "Page Not Found" });
    }
  }

  if (story.value && story.value.content.seo) {
    useSeoTags(story.value.content.seo, story.value);

    if (story?.value?.content?.Noindex === true) {
      const rule = useRobotsRule();
      rule.value = "noindex, nofollow";
    }
  }

  onMounted(() => {
    if (isPreview && story.value && story.value.id) {
      useStoryblokBridge(story.value.id, (evStory) => (story.value = evStory), {
        resolveRelations: resolve_relations,
      });
    }
  });
</script>
